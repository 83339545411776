import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: 7px;\n  width: 36px;\n  margin-bottom: 10px;\n  cursor: pointer;\n\n  svg {\n    width: 13px;\n    height: 13px;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: 255px;\n  height: 255px;\n  max-width: 100%;\n\n  > img {\n    max-width: 100%;\n    max-height: 100%;\n    object-fit: cover;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  color: ", ";\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  color: ", ";\n  display: flex;\n  ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  font-weight: normal;\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    padding: 1.8rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  border: 3px solid transparent;\n  color: ", ";\n  padding: 2.2rem;\n  text-align: center;\n  max-height: 30rem;\n  transition: 0.3s;\n\n  :hover {\n    border: 3px solid ", ";\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: left;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import { css } from "styled-components";
import ReactSVG from "react-svg";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Wrapper = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.black;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.primary;
}, media.largeScreen(_templateObject3()));
export var Title = styled.h4(_templateObject4(), textProps);
export var Price = styled.p(_templateObject5(), textProps);
export var Info = styled.p(_templateObject6(), function (props) {
  return props.theme.colors.primary;
}, textProps);
export var Warning = styled.p(_templateObject7(), function (props) {
  return props.theme.colors.error;
}, textProps);
export var Image = styled.div(_templateObject8());
export var BellIconWrapper = styled(ReactSVG)(_templateObject9(), function (props) {
  return props.theme.colors.primary;
});