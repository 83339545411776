import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { BackInStockSubscribeModal } from "@components/organisms/BackInStockSubscribeModal";
import { Button } from "@components/atoms";
import { Tooltip } from "@components/molecules";
import QuantityInput from "../../molecules/QuantityInput";
import AddToCartButton from "../../molecules/AddToCartButton";
import ProductVariantPicker from "../ProductVariantPicker";
import * as S from "./styles";
import { getAvailableQuantity, getProductPrice, canAddToCart } from "./stockHelpers";
export var LOW_STOCK_QUANTITY = 2;

var AddToCartSection = function AddToCartSection(_ref) {
  var productId = _ref.productId,
      availableForPurchase = _ref.availableForPurchase,
      isAvailableForPurchase = _ref.isAvailableForPurchase,
      isFastRestock = _ref.isFastRestock,
      items = _ref.items,
      name = _ref.name,
      productPricing = _ref.productPricing,
      productVariants = _ref.productVariants,
      queryAttributes = _ref.queryAttributes,
      onAddToCart = _ref.onAddToCart,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      setVariantId = _ref.setVariantId,
      variantId = _ref.variantId;
  var intl = useIntl();

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      quantity = _useState2[0],
      setQuantity = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      variantStock = _useState4[0],
      setVariantStock = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      variantPricing = _useState6[0],
      setVariantPricing = _useState6[1];

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      showSubscribeModal = _useState8[0],
      setShowSubscribeModal = _useState8[1];

  var availableQuantity = getAvailableQuantity(items, variantId, variantStock, !!isFastRestock);
  var isOutOfStock = !!variantId && variantStock <= 0;
  var noPurchaseAvailable = !isAvailableForPurchase && !availableForPurchase;
  var purchaseAvailableDate = !isAvailableForPurchase && availableForPurchase && Date.parse(availableForPurchase);
  var isNoItemsAvailable = !!variantId && !isOutOfStock && !availableQuantity;
  var isLowStock = !!variantId && !isOutOfStock && !isNoItemsAvailable && availableQuantity < LOW_STOCK_QUANTITY;
  var disableButton = !canAddToCart(items, !!isAvailableForPurchase, variantId, variantStock, quantity, !!isFastRestock);
  var currentProductVariant = productVariants.find(function (e) {
    return e.id === variantId;
  });

  var renderWarningMessage = function renderWarningMessage(message, testingContextId, tooltip) {
    return React.createElement(S.WarningMessage, {
      "data-test": "stockWarningMessage",
      "data-testid": testingContextId
    }, message, tooltip ? React.createElement(Tooltip, {
      text: tooltip
    }) : null);
  };

  var renderErrorMessage = function renderErrorMessage(message, testingContextId) {
    return React.createElement(S.ErrorMessage, {
      "data-test": "stockErrorMessage",
      "data-testid": testingContextId
    }, message);
  };

  var onVariantPickerChange = function onVariantPickerChange(_selectedAttributesValues, selectedVariant) {
    if (!selectedVariant) {
      setVariantId("");
      setVariantPricing(null);
      setVariantStock(0);
      return;
    }

    setVariantId(selectedVariant.id);
    setVariantPricing(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.pricing);
    setVariantStock(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.quantityAvailable);
  };

  return React.createElement(S.AddToCartSelection, null, React.createElement(S.ProductNameHeader, {
    "data-test": "productName"
  }, name), currentProductVariant && React.createElement(S.ProductPricingOrText, null, "KOD: ", currentProductVariant.sku), isOutOfStock && !isFastRestock ? renderErrorMessage(intl.formatMessage(commonMessages.outOfStock), "outOfStock") : React.createElement(S.ProductPricingOrText, null, getProductPrice(productPricing, variantPricing)), noPurchaseAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noPurchaseAvailable), "notAvailable"), purchaseAvailableDate && renderErrorMessage(intl.formatMessage(commonMessages.purchaseAvailableOn, {
    date: new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(purchaseAvailableDate),
    time: new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric"
    }).format(purchaseAvailableDate)
  }), "timeRestrictedAvailability"), isLowStock && renderWarningMessage(intl.formatMessage(commonMessages.lowStock), "lowStockWarning"), isOutOfStock && !!isFastRestock && renderWarningMessage(intl.formatMessage(commonMessages.fastRestock), "fastRestockWarning", "Towar dostępny u naszego dostawcy. Zamówienie zostanie zrealizowane w ciągu 2-6 dni roboczych."), isNoItemsAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noItemsAvailable), "noItemsAvailable"), React.createElement(S.VariantPicker, {
    hidden: productVariants.length <= 1
  }, React.createElement(ProductVariantPicker, {
    productVariants: productVariants,
    onChange: onVariantPickerChange,
    selectSidebar: true,
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: onAttributeChangeHandler
  })), !isOutOfStock || !!isFastRestock ? React.createElement(React.Fragment, null, React.createElement(S.QuantityInput, null, React.createElement(QuantityInput, {
    quantity: quantity,
    maxQuantity: availableQuantity,
    disabled: (isOutOfStock || isNoItemsAvailable) && !isFastRestock,
    onQuantityChange: setQuantity,
    hideErrors: !variantId || (isOutOfStock || isNoItemsAvailable) && !isFastRestock,
    testingContext: "addToCartQuantity"
  })), React.createElement(AddToCartButton, {
    onSubmit: function onSubmit() {
      return onAddToCart(variantId, quantity);
    },
    disabled: disableButton
  })) : null, isOutOfStock && !isFastRestock ? React.createElement(S.WaitListButton, null, React.createElement(Button, {
    fullWidth: true,
    testingContext: "addProductToCartButton",
    onClick: function onClick() {
      return setShowSubscribeModal(true);
    },
    color: "primary"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.AddToCartSection.AddToCartSection.334062982",
    defaultMessage: "zapisz si\u0119 na powiadomienie"
  })), React.createElement(BackInStockSubscribeModal, {
    show: showSubscribeModal,
    hideModal: function hideModal() {
      return setShowSubscribeModal(false);
    },
    productData: {
      id: productId,
      name: name
    }
  })) : null);
};

AddToCartSection.displayName = "AddToCartSection";
export default AddToCartSection;