import { IconButton, Tooltip as MuiTooltip } from "@material-ui/core";
import { Icon } from "@components/atoms";
import React from "react";
import * as S from "./styles";
export function Tooltip(props) {
  return React.createElement(MuiTooltip, {
    title: React.createElement(S.Text, null, props.text),
    arrow: true,
    enterTouchDelay: 0,
    placement: "left-start"
  }, React.createElement(S.Icon, null, React.createElement(IconButton, {
    size: "small"
  }, React.createElement(Icon, {
    size: 12,
    color: "black",
    name: "question_mark"
  }))));
}