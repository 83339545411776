import React from "react";
import { TaxedMoney } from "@components/containers";
import { Thumbnail, Tooltip } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { useIntl } from "react-intl";
import { LOW_STOCK_QUANTITY } from "@components/organisms";
import bellImg from "images/bell.svg";
import { generateProductUrl } from "@temp/core/utils";
import { Link } from "react-router-dom";
import * as S from "./styles";
var TOOLTIP_TEXT = "Towar dostępny u naszego dostawcy. Zamówienie zostanie zrealizowane w ciągu 2-6 dni roboczych.";
export var ProductTile = function ProductTile(_ref) {
  var product = _ref.product,
      onSubscribe = _ref.onSubscribe;
  var intl = useIntl();
  var productUrl = generateProductUrl(product.id, product.name);
  var price = product.pricing && product.pricing.priceRange && product.pricing.priceRange.start ? product.pricing.priceRange.start : undefined;

  var getWarningLabel = function getWarningLabel() {
    if (product.inStock === null) {
      return null;
    }

    if (product.inStock <= 0) {
      if (product.fastRestock) {
        return React.createElement(React.Fragment, null, React.createElement(S.Info, null, intl.formatMessage(commonMessages.fastRestock), React.createElement(Tooltip, {
          text: TOOLTIP_TEXT
        })));
      }

      return React.createElement(React.Fragment, null, React.createElement(S.Warning, null, intl.formatMessage(commonMessages.outOfStock)), onSubscribe && React.createElement(S.BellIconWrapper, {
        path: bellImg,
        onClick: onSubscribe
      }));
    }

    if (product.inStock < LOW_STOCK_QUANTITY) {
      return React.createElement(S.Info, null, intl.formatMessage(commonMessages.lowStock));
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(S.Title, {
    "data-test": "productTile"
  }, product.name)), getWarningLabel(), React.createElement(S.Price, {
    "data-test": "productPrice"
  }, React.createElement(TaxedMoney, {
    taxedMoney: price
  })), React.createElement(Link, {
    to: productUrl
  }, React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(Thumbnail, {
    source: product
  }))));
};