import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-radius: 50%;\n  width: fit-content;\n  height: fit-content;\n  padding: 2px;\n  display: flex;\n  margin-left: 10px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Icon = styled.span(_templateObject(), function (props) {
  return props.theme.colors.primary;
});
export var Text = styled.p(_templateObject2(), function (props) {
  return props.theme.typography.smallFontSize;
});